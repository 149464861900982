<template>
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :active_tab="active_tab" :tabs="tabs" />
        <v-row :style="`direction:`+lang.dir">
            <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px;border-radius:5px" @click="getReport()">{{lang.search}}</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px;display:none;" class="mytab mytab1 mt-3"  @click="exportE('e')">{{lang.export_excel}}</b-button>
                <!-- <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button> -->
            </v-col>
        </v-row>

        <template>
        <v-simple-table
            :headers="headers"
            :items="cardrows"
            :items-per-page="10"
            class="elevation-1"
        >
            <thead>
                <tr>
                    <th class="text-center" v-for="(head,ind) in headers" :key="ind">{{ head.text }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row,ind) in cardrows" :key="ind">
                    <td class="text-center">{{ row.date }}</td>
                    <td class="text-center" style="background:green !important;color:#FFF !important;">{{ row.income }}</td>
                    <td class="text-center">{{ row.cash }}</td>
                    <td class="text-center">{{ row.othermethod }}</td>
                    <td class="text-center" style="background:#d8ff00 !important;color:red !important;">{{ row.invoices_total }}</td>
                    <td class="text-center">{{ row.invoices_remain }}</td>
                    <td class="text-center" style="background:lightblue !important;">{{ row.vat }}</td>
                    <td class="text-center" style="background:green !important;color:#FFF !important;">{{ row.purchase }}</td>
                    <td class="text-center" style="background:red !important;color:#FFF !important;">{{ row.expenses }}</td>
                    <td class="text-center" style="background:lightgreen !important;">{{ row.period_expenses }}</td>
                    <td class="text-center">{{ row.advanced_payment }}</td>
                    <td class="text-center">{{ row.other_expenses }}</td>
                    <td class="text-center">{{ row.exp_vat }}</td>
                    <td class="text-center">{{ row.exp_total }}</td>
                    
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{lang.totals}}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ income }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ cash }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ othermethod }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ invoices_total }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ invoices_remain }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ vat }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ purchase }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ expenses }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ period_expenses }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ advanced_payment }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ other_expenses }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ exp_vat }}</th>
                    <th class="text-center" style="background:#eee;color:green !important;font-weight:bold">{{ exp_total }}</th>
                </tr>
            </tfoot>
        </v-simple-table>
        </template>

        
        <b-sidebar id="cards-reports-search" style="direction:ltr" right title="خيارات التقرير" shadow >
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl"> 
                <v-row>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="تاريخ الاستلام - من"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog1"
                            v-model="modal1"
                            :return-value.sync="date1"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date1"
                                label="تاريخ الاستلام - إلى"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date1"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal1 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date1)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        
                    </v-col>
                </v-row>
            </div>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" class="ma-2" style="width:100px;">بحث</b-button>
                </div>
            </template>
        </b-sidebar>
        <outReceipt />
        <div class="loadingPage" v-if="$store.state.showLoading">
            <v-progress-circular
                :width="3"
                color="green"
                indeterminate
            ></v-progress-circular>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import outReceipt from '@/components/outReceipt.vue';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
export default{
  components: { outReceipt, BreadCrumbs, TabsComp },
    data() {
        return {
            receipt_total: 0,
            cardrows:[
                
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            active_tab:1,
            
            
            activeClick: 0,
            sdate: '',
            edate: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
            return {
                text: this.lang.dealy_report,
                    disabled: true,
                    to: '/reports/daily-reports',
                }
        },
        headers: function() {
            return [
                {text: this.lang.date , align: 'center', value:'date'},
                {text: this.lang.income , align: 'center', value:'income'},
                {text: this.lang.cash , align: 'center', value:'cash'},
                {text: this.lang.othermethod , align: 'center', value:'othermethod'},
                {text: this.lang.invoices_total , align: 'center', value:'invoices_total'},
                {text: this.lang.invoices_remain , align: 'center', value:'invoices_remain'},
                {text: this.lang.vat , align: 'center', value:'vat'},
                {text: this.lang.purchase , align: 'center', value:'purchase'},
                {text: this.lang.expenses , align: 'center', value:'expenses'},
                {text: this.lang.period_expenses , align: 'center', value:'period_expenses'},
                {text: this.lang.advanced_payment , align: 'center', value:'advanced_payment'},
                {text: this.lang.other_expenses , align: 'center', value:'other_expenses'},
                {text: this.lang.exp_vat , align: 'center', value:'exp_vat'},
                {text: this.lang.exp_total , align: 'center', value:'exp_total'},
            ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        income: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].income)
            }
            return this.$RoundNum(t);
        },
        cash: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].cash)
            }
            return this.$RoundNum(t);
        },
        othermethod: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].othermethod)
            }
            return this.$RoundNum(t);
        },
        invoices_total: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].invoices_total)
            }
            return this.$RoundNum(t);
        },
        invoices_remain: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].invoices_remain)
            }
            return this.$RoundNum(t);
        },
        vat: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].vat)
            }
            return this.$RoundNum(t);
        },
        purchase: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].purchase)
            }
            return this.$RoundNum(t);
        },
        expenses: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].expenses)
            }
            return this.$RoundNum(t);
        },
        period_expenses: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].period_expenses)
            }
            return this.$RoundNum(t);
        },
        advanced_payment: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].advanced_payment)
            }
            return this.$RoundNum(t);
        },
        other_expenses: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].other_expenses)
            }
            return this.$RoundNum(t);
        },
        exp_vat: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].exp_vat)
            }
            return this.$RoundNum(t);
        },
        exp_total: function(){
            let t = 0;
            for(let i=0;i<this.cardrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardrows[i].exp_total)
            }
            return this.$RoundNum(t);
        },
    },
    methods: {
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type',1);
            post.append('id',item.id);
        },
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.cardrows;
            const reportname = 'تقرير اليومية';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    keys.push(myhead[i].value);
                }
            }
            const post = new FormData();
            post.append("export" , 'inrecepit');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        
        chActiv(id){
            this.activeClick = id;
        },
        calcRows(){
            this.income_total = 0;
            this.cash_total = 0;
            this.span_total = 0;
            this.vat_total = 0;
            this.parts_total = 0;
            this.service_total = 0;
            this.expense_total = 0;
            this.remain_total = 0;
            this.dexpenses_total = 0;
            this.finaltotal_total = 0;
            this.receipt_total = 0;
            
            for(let i = 0; i < this.cardrows.length; i++ ){
                this.income_total = +this.income_total + +this.cardrows[i].total_income
                this.cash_total = +this.cash_total + +this.cardrows[i].cash
                this.span_total = +this.span_total + +this.cardrows[i].span
                this.cardrows[i].vat = this.$RoundNum(this.cardrows[i].vat);
                this.vat_total = +this.vat_total + +this.cardrows[i].vat
                this.parts_total = +this.parts_total + +this.cardrows[i].parts
                this.service_total = +this.service_total + +this.cardrows[i].services
                this.expense_total = +this.expense_total + +this.cardrows[i].expense
                this.cardrows[i].expense = this.$RoundNum(this.cardrows[i].expense);
                this.remain_total = +this.remain_total + +this.cardrows[i].remaining
                this.dexpenses_total = +this.dexpenses_total + +this.cardrows[i].dexpenses
                this.finaltotal_total = +this.finaltotal_total + +this.cardrows[i].finaltotal
                this.receipt_total = +this.receipt_total + +this.cardrows[i].outreceipt
            }
            this.income_total = this.$RoundNum(this.income_total);
            this.cash_total = this.$RoundNum(this.cash_total);
            this.span_total = this.$RoundNum(this.span_total);
            this.vat_total = this.$RoundNum(this.vat_total);
            this.parts_total = this.$RoundNum(this.parts_total);
            this.service_total = this.$RoundNum(this.service_total);
            this.expense_total = this.$RoundNum(this.expense_total);
            this.remain_total = this.$RoundNum(this.remain_total);
            this.finaltotal_total = this.$RoundNum(this.finaltotal_total);
            this.dexpenses_total = this.$RoundNum(this.dexpenses_total);
            this.receipt_total = this.$RoundNum(this.receipt_total);
        },
        getDailyReportss(){
            const post = new FormData();
            post.append("type","getDailyReport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.$store.state.showLoading = false
                const res = response.data;
                
                this.cardrows = res.results.data;
                this.calcRows();
            })
        },
        getDailyReport(){
            const post = new FormData();
            post.append("type","getDailyReports");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.cardrows = res.results.data;
                this.calcRows();
            })
        },
        postDay(items){
            const post = new FormData();
            post.append("type","closeDay");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            for(var item in items){
                post.append('data['+item+']',items[item]);
                
            }
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getDailyReport();
            })
        }
    },
    created() {
        this.getDailyReport();
    },
}
</script>
<style>
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
</style>